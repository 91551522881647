import { emitter } from "@/global-prototypes";
import axios from "@/utils/appAxios";

export default {
  getBranchLocations: ({ commit }, company) => {
    axios
      .get("/company/branches/list/branch-locations", { params: { company } })
      .then((result) => {
        commit("setBranchLocations", result.data);
      })
      .catch((err) => {
        console.log(err);
        emitter.$emit("Notification", {
          variant: "danger",
          title: "Hata Oluştu",
        });
      });
  },
  getCompanyBranches: ({ commit }, query) => {
    axios
      .get("/company/branches/list/paginated", { params: query, loading: "table" })
      .then((result) => {
        commit("setBranches", result.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  newBranch: ({ commit }, { data, callback }) => {
    axios
      .post("/company/branches", data, { loading: "sidebar" })
      .then(() => {
        callback && callback();
        commit("cleanAppSidebar");
        emitter.$emit("Notification", {
          variant: "success",
          title: "İşlem Başarılı",
        });
      })
      .catch((error) => {
        console.log(error);
        emitter.$emit("Notification", {
          variant: "danger",
          title: "Hata Oluştu",
        });
      });
  },
  updateBranch: ({ commit }, { id, data, callback }) => {
    axios
      .patch(`/company/branches/${id}`, data, { loading: "sidebar" })
      .then(() => {
        commit("cleanAppSidebar");
        emitter.$emit("Notification", {
          variant: "success",
          title: "İşlem Başarılı",
        });
        callback && callback();
      })
      .catch((error) => {
        console.log(error);
        emitter.$emit("Notification", {
          variant: "danger",
          title: "Hata Oluştu",
        });
      });
  },
  getBranchOptions(_, company) {
    return new Promise((resolve, reject) => {
      axios
        .get("company/branches/list/branch-options", { params: { company } })
        .then((result) => {
          resolve(result.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
